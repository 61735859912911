import React from "react";
import styles from "./Skills.module.css";
import CodeEditor from "./CodeEditor/CodeEditor";
 
function Skills() {
  return (
    <div className={styles["wrapper"]}>
      <div className={styles["container"]}>
        <div className={styles["typewriter"]}>
          <h4>{"< skills />"}</h4>
        </div>
       
        <div className={styles["code-space"]}>
          <p>Explore my skills and expertise in web development, highlighting capability in various technologies and tools. Get a glimpse of my professional background.</p>
          <CodeEditor />
        </div>
      </div>
    </div>
  ); 
}

export default Skills;
