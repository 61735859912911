import React, { useState, useEffect } from "react";
import styles from './SocialSlider.module.css'
import { FaThreads, FaSnapchat, FaInstagram } from "react-icons/fa6";

const cardData = [
  {
    title: "Instagram",
    href: "https://instagram.com/felixxianoo?utm_source=qr",
    icon: <FaInstagram />,
  },
  {
    title: "Snapchat",
    href: "https://t.snapchat.com/dm9RWYnG",
    icon: <FaSnapchat />,
  },
  {
    title: "Threads",
    href: "https://www.threads.net/@felixxianoo",
    icon: <FaThreads />,
  },
];

const SocialSlider = () => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % cardData.length);
    }, 2500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <div className={styles["carousel"]}>
      {cardData.map((item, index) => (
        <a key={index} href={item.href} target="_blank" rel="noopener noreferrer">
          <div
            className={`${styles["card"]} ${
              activeIndex === index ? styles["active"] : ""
            }`}
          >
            {React.cloneElement(item.icon, { style: { color: '#000000' } })}
          </div>
        </a>
      ))}
    </div>
  );
};

export default SocialSlider;
