import React from "react";
import styles from "./Greet.module.css";

function Greet() {
  return (
    <div className={styles["waving-animation"]}>
      <div className={styles["waving-bottom"]}>
        <div className={styles["waving-body"]}></div>
        <svg
        width="80px"
        height="80px"
        viewBox="0 0 64 64"
        xmlns="http://www.w3.org/2000/svg"
        aria-hidden="true"
        role="img"
        className={styles["waving-face"]}
        preserveAspectRatio="xMidYMid meet"
      >
        <path
          d="M32 2C15.432 2 2 15.432 2 32s13.432 30 30 30s30-13.432 30-30S48.568 2 32 2zm0 57.5C16.836 59.5 4.5 47.164 4.5 32S16.836 4.5 32 4.5S59.5 16.836 59.5 32S47.164 59.5 32 59.5z"
          fill="#b9b9b980"
        ></path>
        <path
          d="M14.859 28.854c1.802-1.901 3.957-2.659 6.206-2.659c2.25 0 4.405.758 6.207 2.659c.479.504 1.438-.423 1.254-.938c-1.859-5.112-4.66-7.669-7.461-7.669s-5.603 2.558-7.46 7.669c-.184.515.774 1.441 1.254.938"
          fill="#b9b9b980"
        ></path>
        <path
          d="M36.729 28.854c1.803-1.901 3.957-2.659 6.207-2.659s4.403.758 6.207 2.659c.478.504 1.437-.423 1.254-.938c-1.859-5.112-4.66-7.669-7.461-7.669c-2.802 0-5.603 2.558-7.461 7.669c-.185.515.774 1.441 1.254.938"
          fill="#b9b9b980"
        ></path>
        <path
          d="M51.643 35.344C47.159 34.682 40.535 34 31.999 34h-.001c-8.535 0-15.159.682-19.641 1.344C10.611 35.602 10 36.607 10 37.41C10 47 13.492 50 27.811 50h8.381C50.508 50 54 47 54 37.41c0-.803-.613-1.808-2.357-2.066M32 48c-6.376 0-15.467 0-15.223-2.053c.044-.367.152-.803.289-1.276c.158-.526.28-.671 1.378-.671h27.112c1.098 0 1.223.145 1.377.671c.141.474.246.909.289 1.276C47.467 48 38.376 48 32 48m16.934-9.831l-1.113 2.909c-.195.507-.762.922-1.264.922H17.443c-.5 0-1.068-.415-1.262-.922l-1.115-2.909c-.194-.507.053-.983.549-1.058c0 0 7.392-1.111 16.385-1.111c8.992 0 16.385 1.111 16.385 1.111c.495.077.742.551.549 1.058"
          fill="#b9b9b980"
        ></path>
      </svg>
      </div>
      <div className={styles["waving-arm-wrap"]}>
        <div className=
        {styles["waving-arm"]}>
       
        </div>
      </div>
    </div>
  );
}

export default Greet;
