/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import styles from "./Home.module.css";
import { Link } from 'react-router-dom';
 
function Home(props) {
  
  const { darkMode } = props;

  return (
    <div className={styles["wrapper"]}>
      <div className={styles["css-typing"]}>
        <p>let felixxianoo = {"{"}</p>
        <p className={styles["child2"]}>firstName: 'Felix',</p>
        <p>lastName: 'Perez' {"}"};</p>
      </div> 
      <div className={styles["bio"]}>
        <h3>
          // somewhere building websites and web applications while fighting, arguing, and enduring headaches with my companion {'(myCode)'} 
          <br />
           // yet remain inseparable, ultimately contributing to the success of the overall product
        </h3>
        <Link to='/resume' target={"_blank"}>
          <button className={`${styles["ui-btn"]} ${darkMode && styles.dark}`}>
            <span>RESUME</span>
          </button>
        </Link>  
      </div>
    </div>
  );
}

export default Home;
