import React, { useState } from "react";
import axios from "axios";
import styles from "./Contact.module.css";
import Greet from "./SocialSlider/Greet/Greet";
import SocialPopup from "./SocialSlider/SocialPopup";

function Contact() {
  const [formData, setFormData] = useState({
    fullName: "",
    contactInfo: "",
    message: "",
  });

  const [validationErrors, setValidationErrors] = useState({
    fullName: "",
    contactInfo: "",
  });

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const handleSubmit = async () => {
    try {
      if (!formData.fullName || !formData.contactInfo ) {
        setValidationErrors({
          fullName: !formData.fullName ? "Name is required" : "",
          contactInfo: !formData.contactInfo ? "Contact Info is required" : "",
        });
        return;
      }

      if (!validateContactInfo(formData.contactInfo)) {
        setValidationErrors({
          ...validationErrors,
          contactInfo: "Please enter a valid contact info",
        });
        return;
      }

      setValidationErrors({
        fullName: "",
        contactInfo: "",
      });

      await axios.post("https://api.felixxianoo.com/api/submit-form", formData);
      setFormData({
        fullName: "",
        contactInfo: "",
        message: "",
      });

      setBtnState("sent");

      setTimeout(() => {
        setIsFormSubmitted(false);
        setBtnState("send");
      }, 3000);
    } catch (error) {
      console.error(error);
    }
  };

  const [btnState, setBtnState] = useState("send");
  const [, setInputValue] = useState("");
  const [rows, setRows] = useState(1);


  const validateContactInfo = (contactInfo) => {
    
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const phoneRegex = /^[0-9]{10}$/; 

    if (emailRegex.test(contactInfo)) {
    
      return true;
    } else if (phoneRegex.test(contactInfo)) {
     
      return true;
    } else {
      
      return false;
    }
  };
  

  const handleChange = (e) => {

    const { name, value } = e.target; 

    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    setValidationErrors((prevErrors) => ({
      ...prevErrors,
      [name]: "",
    }));

    const textareaLineHeight = 24; 
    
    const previousRows = e.target.rows;
    e.target.rows = 1; 

    const currentRows = Math.floor(e.target.scrollHeight / textareaLineHeight);

    if (currentRows === previousRows) {
      e.target.rows = currentRows;
    }

    setInputValue(e.target.value);
    setRows(currentRows);
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
    console.log('workin');
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
  };

  return (
    <div className={styles["wrapper"]}>
      <div>
      {isPopupOpen && <SocialPopup onClose={handleClosePopup} />}
      </div>
      <div className={styles["container"]}>
        <div className={styles["typewriter"]}>
          <h4>{"< contact />"}</h4>
        </div>
        <div className={styles["section"]}>
          <p className={styles["fr-ptag"]}>
            Please be aware that the completion of this form is exclusively for
            business-related matters. If you happen to have any queries please
            don't hesitate to initiate a conversation with me through direct
            messaging on my <span className={styles["s-media"]} onClick={handleOpenPopup}>social platforms</span>.
          </p> 
          <div className={styles["inner-section"]}>
          {isFormSubmitted && (
            <p className={styles["success-message"]}>Form successfully submitted!</p>
          )}
            <div className={styles["form"]}>
              <div className={styles["group"]}>
                <input
                  required
                  type="text"
                  className={styles["input"]}
                  value={formData.fullName}
                  onChange={handleChange}
                  name="fullName"
                />
                <span className={styles["highlight"]}></span>
                <span className={styles["bar"]}></span>
                <label className={styles["form-label"]}>fullName</label>
                {validationErrors.fullName && (
              <p className={styles["error-message"]}>{validationErrors.fullName}</p>
            )}
              </div>
              <div className={styles["group"]}>
                <input
                  required
                  type="text"
                  className={styles["input"]}
                  value={formData.contactInfo}
                  onChange={handleChange}
                  name="contactInfo"
                />
                <span className={styles["highlight"]}></span>
                <span className={styles["bar"]}></span>
                <label className={styles["form-label"]}>contactInfo</label>
                {validationErrors.contactInfo && (
              <p className={styles["error-message"]}>{validationErrors.contactInfo}</p>
            )}
              </div>
              <div className={styles["group"]}>
                <textarea
                  required
                  value={formData.message}
                  onChange={handleChange}
                  rows={rows}
                  className={styles["message-input"]}
                  name="message"
                />
                <span className={styles["highlight"]}></span>
                <span className={styles["bar"]}></span>
                <label className={styles["form-label"]}>message</label>
              </div>

              <div>
                <button
                  onClick={handleSubmit}
                  className={`${styles["form-send-btn"]} ${
                    btnState === "sent" ? styles.sent : ""
                  }`}
                  disabled={btnState === "sent"}
                >
                  <span>{btnState}</span>
                </button>
              </div>
            </div> 
            <div className={styles["social-medias"]}>
              <Greet />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
