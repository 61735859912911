import "./App.css";
import React, { createContext, useState, useEffect } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Components/Home/Home";
import Nav from "./Components/Navigation/Nav";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Pdf from "./Components/PDF/Pdf";
import DarkModeToggle from "./Components/DarkMode/DarkModeToggle";
import Skills from "./Components/Skills/Skills";

export const ThemeContext = createContext(null);

function App() {
  
  const storedTheme = localStorage.getItem("theme");
  const [theme, setTheme] = useState(storedTheme || "light");

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  const toggleTheme = () => {
    setTheme((currentTheme) => (currentTheme === "light" ? "dark" : "light"));
  };

  const darkMode = theme === "dark";


  return (
    <BrowserRouter>
      <ThemeContext.Provider value={{ theme, toggleTheme }}>
        <div className="App" id={theme}>
          <Nav darkMode={darkMode}  />
          <Routes>
            <Route path="/" element={<Home darkMode={darkMode} />} />
            <Route path="/about" element={<About />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/skills" element={<Skills/>} />
            <Route path="/resume" element={<Pdf/>} />
          </Routes>
        </div>
        <DarkModeToggle darkMode={darkMode} toggleTheme={toggleTheme} />     
      </ThemeContext.Provider>
    </BrowserRouter>
  ); 
}

export default App;
