import React, { useState } from "react";
import styles from "./CodeEditor.module.css";

function CodeEditor() {
  const lines = Array.from({ length: 21
   }, (_, index) => index + 1);

  const [activeTab, setActiveTab] = useState(1);

  const handleTabClick = (tabNumber) => {
    setActiveTab(tabNumber);
  };

  return (
    <div className={styles.editor}>
      <div className={styles.tabs}>
        <div
          className={`${styles.tab} ${activeTab === 1 && styles.active}`}
          onClick={() => handleTabClick(1)}
        >
          webdev
        </div>
        <div
          className={`${styles.tab} ${activeTab === 2 && styles.active}`}
          onClick={() => handleTabClick(2)}
        >
          technologies
        </div>
        <div
          className={`${styles.tab} ${activeTab === 3 && styles.active}`}
          onClick={() => handleTabClick(3)}
        >
          tools
        </div>
      </div>
      <div className={styles["codes"]}>
        <div className={styles.lineNumbers}>
          {lines.map((line) => (
            <div key={line} className={styles.lineNumber}>
              {line}
            </div>
          ))}
        </div>
        <div className={styles.codeArea}>
          {activeTab === 1 && (
            <code>
              <span>
                <span className={styles["blu-construct"]}>const</span>
                <span className={styles["wite-construct"]}> webDevelopment </span>
                =
                <span className={styles["wrapping-construct"]}>{' {'}</span>
              </span>
              <span>
                <span className={styles["red-construct"]}>   title</span>:
                <span className={styles["org-construct"]}> 'Junior Web Developer'</span>,
              </span>
              <span>
                <span className={styles["red-construct"]}>   message</span>:
                <span className={styles["org-construct"]}> 'Hello World!'</span>,
              </span>
              <span>
                <span className={styles["red-construct"]}>   experience</span>:
                <span className={styles["org-construct"]}> '2 years'</span>,
              </span>
              <span>
                <span className={styles["red-construct"]}>   selfTaught</span>:
                <span className={styles["blu-construct"]}> true</span>,
              </span>
              <span> <br /></span>
              <span>
                <span className={styles["red-construct"]}>  meta</span>:
                <span className={styles["prpl-construct"]}>{' {'}</span>
              </span>
              <span>
                <span className={styles["red-construct"]}>   technologiesUsed</span>:
                <span className={styles["blu-construct"]}> 8</span>,
              </span>
              <span>
                <span className={styles["red-construct"]}>   mentorship</span>:
                <span className={styles["org-construct"]}> 'From senior developers'</span>,
              </span>
              <span>
                <span className={styles["red-construct"]}>   learningResources</span>: [
                <span className={styles["org-construct"]}>'Documentation, Online courses'</span>],
              </span>
              <span>
                <span className={styles["prpl-construct"]}>{'  }'}</span>;
              </span>
              <span> 
                <span className={styles["wrapping-construct"]}>{`}`}</span>;
              </span>
            </code>
          )}
          {activeTab === 2 && (
            <code>
              
              <span>
                <span className={styles["blu-construct"]}>const</span>
                <span className={styles["wite-construct"]}> myTechStack </span>
                =
                <span className={styles["wrapping-construct"]}>{' {'}</span>
              </span>
              <span>
                <span className={styles["red-construct"]}>  frontend</span>:
                <span className={styles["prpl-construct"]}>{' {'}</span>
              </span>
              <span>
                <span className={styles["red-construct"]}>   languages</span>: [
                <span className={styles["org-construct"]}>'HTML','CSS','JavaScript','TypeScript'</span>],
              </span>
              <span>
                <span className={styles["red-construct"]}>   librariesFrameworks</span>:[
                <span className={styles["org-construct"]}>'React'</span>],
              </span>
              <span>
                <span className={styles["red-construct"]}>   cms</span>:[
                <span className={styles["org-construct"]}>'WordPress'</span>],
              </span>
              <span>
                <span className={styles["red-construct"]}>   styling</span>:[
                <span className={styles["org-construct"]}>'Sass'</span>],
              </span>
              <span>
                <span className={styles["prpl-construct"]}>{'  }'}</span>;
              </span>
              <span>
                <span className={styles["red-construct"]}>  backend</span>:
                <span className={styles["prpl-construct"]}>{' {'}</span>
              </span>
              <span>
                <span className={styles["red-construct"]}>   languages</span>: [
                <span className={styles["org-construct"]}>'JavaScript (NodeJS)'</span>],
              </span>
              <span>
                <span className={styles["red-construct"]}>   frameworks</span>:[
                <span className={styles["org-construct"]}>'Express'</span>],
              </span>
              <span>
                <span className={styles["red-construct"]}>   databases</span>:[
                <span className={styles["org-construct"]}>'MongoDB'</span>],
              </span>
              <span>
                <span className={styles["red-construct"]}>   api</span>:[
                <span className={styles["org-construct"]}>'REST'</span>],
              </span>
              <span>
                <span className={styles["prpl-construct"]}>{'  }'}</span>;
              </span>
              <span> 
                <span className={styles["wrapping-construct"]}>{`}`}</span>;
              </span>
            </code>
          )}
          {activeTab === 3 && (
           <code>
            <span>
                <span className={styles["blu-construct"]}>const</span>
                <span className={styles["wite-construct"]}> myTools </span>
                =
                <span className={styles["wrapping-construct"]}>{' {'}</span>
              </span>
              <span>
                <span className={styles["red-construct"]}>   coding</span>:
                <span className={styles["blu-construct"]}> true</span>,
              </span>
              <span>
                <span className={styles["red-construct"]}>   productivity</span>:
                <span className={styles["blu-construct"]}> 8</span>,
              </span>
              <span> <br /></span>
              <span>
                <span className={styles["red-construct"]}>  tools</span>:
                <span className={styles["prpl-construct"]}>{' {'}</span>
              </span>
              <span>
                <span className={styles["red-construct"]}>   packageManager</span>:[
                <span className={styles["org-construct"]}>'NPM'</span>],
              </span>
              <span>
                <span className={styles["red-construct"]}>   versionControl</span>:[
                <span className={styles["org-construct"]}>'Git'</span>],
              </span>
              <span>
                <span className={styles["red-construct"]}>   collaboration</span>:[
                <span className={styles["org-construct"]}>'Github, Postman'</span>],
              </span>
              <span>
                <span className={styles["prpl-construct"]}>{'  }'}</span>;
              </span>
              <span> 
                <span className={styles["wrapping-construct"]}>{`}`}</span>;
              </span>
           </code>
          )}
        </div>
      </div>
    </div>
  );
}

export default CodeEditor;
