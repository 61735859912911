import React from "react";
import styles from "./About.module.css";
import smileyImg from "../imgs/felixx.jpg";

function About() {
  return (
    <div className={styles["wrapper"]}>
      <div className={styles["container"]}>
        <div className={styles["heading"]}>
          <div className={styles["typewriter"]}>
            <h4>{"< about />"}</h4>
          </div>
          <div className={styles["content"]}>
            <div className={styles["title"]}>
              <span className={styles["abt-intro"]}>
                Hello, I'm Felix Perez, a software developer based in Houston.
                <br />
                With a knack for crafting compelling user experiences, I
                specialize in both front-end and back-end technologies. I excel
                in designing user-friendly interfaces, implementing efficient
                server-side logic, and integrating databases for seamless web
                application functionality. My goal is to bring ideas to life
                through clean, efficient, and innovative code. <br /> Let's
                create something amazing together! <br />
                <br />
              </span>
              <img
                className={styles["smiley"]}
                src={smileyImg}
                alt="Felix's Smiley"
              />
            </div>
            <span>
              Beyond the realm of coding, I find immense joy from the outdoors,
              appreciating the beauty of nature and seeking adventure in its
              vast landscapes. At heart, I'm a chess aficionado, and you're
              invited to join me on{" "}
              <a
                className={styles["chess-link"]}
                href="https://play.chess.com/QZCRx"
                target="_blank"
                rel="noreferrer"
              >
                chess.com/felixxianoo
              </a>{" "}
              for a game. <br />
              A small obsession with coffee & mate <br />
              <br />
              Above all, my faith in Jesus holds paramount importance, guiding
              my values and providing a foundation for everything I do. <br />
              Why not utilize my own platform as a means to spread the message
              of the gospel?
              <br />
            </span>
            <span>
              Because of sin evil came in the world. We were born in a falling
              creation and with a sinful nature that makes us sinners, among
              which we need a way of escape. <br />

             <span className={styles["bible-verse"]}>Ecclesiastes 7:20</span> "For there is not a just man on earth who does
              good and does not sin." <br /> <br />
              The most pleasant way God chose as the way of escape was by
              sending Jesus. A holy and just vessel that came from one purpose.{" "}
              <br />
              <span className={styles["bible-verse"]}>1 Peter 3:18</span> "For Christ also suffered once for sins, the just for
              the unjust, that He might bring us to God, being put to death in
              the flesh but made alive by the Spirit," <br /> <br />
              It's what Jesus Christ did on the cross how we are redeemed not by
              our own works. (being a good person, keeping the commandments,
              etc.) <br />
              <span className={styles["bible-verse"]}>Romans 6:23</span> "For the wages of sin is death, but the gift of God is
              eternal life in Christ Jesus our Lord." <br /> <br />
              We don't stay in sin because obviously that's what separated us
              from God in the first place. So by setting our faith in Jesus, now
              we live our life how it would be pleasing to Him. <br />
              <span className={styles["bible-verse"]}>Galatians 2:20</span> "I have been crucified with Christ; it is no longer
              I who live, but Christ lives in me; and the life which I now live
              in the flesh I live by faith in the Son of God, who loved me and
              gave Himself for me."
            </span>
            <br />
          </div>
        </div>
      </div>
    </div>
  );
}

export default About;
