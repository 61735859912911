import React from "react";
import { useLocation } from "react-router-dom";

function DarkModeToggle({ darkMode, toggleTheme }) {
  const location = useLocation();

  if (location.pathname === "/resume") {
    return null;
  }

  return (
    <div className="light-dark">
      <label>
        <input
          className="checkbox"
          type="checkbox"
          onClick={toggleTheme}
          checked={darkMode}
        />
        <span className="slider"></span>
      </label>
    </div>
  );
}

export default DarkModeToggle;
