import React from 'react'
import cv from './myPrivResume.pdf';

function Pdf() {

  const pdfStyle = {
    height: '100vh',
    width: '100%'
  }

  return (
    <div>
      <iframe src={cv}
      frameborder="0"
      style={{...pdfStyle, zoom: '60%'}} 
      title='PDF'
      >
      </iframe>
    </div>
  )
}

export default Pdf