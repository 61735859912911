import React from 'react';
import popupStyles from './SocialPopup.module.css';
import SocialSlider from './SocialSlider';

function SocialPopup({onClose}) {
  return (
    <div className={popupStyles["popup"]}>
    <div className={popupStyles["popup-content"]}>
      <span className={popupStyles["close-button"]} onClick={onClose}>
        &times;
      </span>
      <div className={popupStyles["social-icons"]}>
                <SocialSlider />
                <p style={{ color: 'black' }}>Let's become amigos!</p>
              </div>
    </div>
  </div>
  )
}

export default SocialPopup