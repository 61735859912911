import React from "react";
import { useState } from "react";
import styles from "./Nav.module.css";
import "../../App.css";
import { useLocation, NavLink } from 'react-router-dom';
import { BsEnvelopeAtFill,BsGithub,BsLinkedin } from "react-icons/bs";

function Nav(props) {

  const { darkMode } = props;
  const [isActive, setIsActive] = useState(false);

  const toggleNav = () => {
    setIsActive(!isActive)
  };

  const closeNav = () => {
    setIsActive(false);
  };

  const lines = [...Array(3)].map((_, index) => {
    const lineClass = styles[`line-${index + 1}`];
    const darkLineClass = darkMode && styles["line-dark"];
    return <span className={`${lineClass} ${darkLineClass}`}></span>;
  });

  const location = useLocation();
  
  // Array of paths where the navigation should not be shown
  const hiddenPaths = ['/resume']; // Add any paths you want to hide the nav on

  if (hiddenPaths.includes(location.pathname)) {
    return null; // Don't render the navigation
  }

  return ( 
    <div className={styles["container"]}>
      <div className={styles["logo"]}>
        <a href="/">
        <svg
          className={`${darkMode && styles.dark}`}
          version="1.1"
          id="Layer_1"
          x="0px"
          y="0px"
          viewBox="0 0 396.08 68.55"
        >
          <style type="text/css">
            {`
	            .st1 { fill: none; }
	            .st2 { fill: #FF4500; }
            `}
          </style>
          <g>
            <path
              class={styles["st0"]}
              d="M0.96,53.31c-0.25-0.32-0.37-0.75-0.37-1.3c0-0.79,0.28-1.38,0.85-1.78c0.57-0.4,1.34-0.59,2.33-0.59h1.85
		V22.16H1.48c-0.44,0-0.8-0.17-1.07-0.52C0.14,21.3,0,20.86,0,20.31c0-0.79,0.28-1.42,0.85-1.89c0.57-0.47,1.34-0.7,2.33-0.7h2.44
		v-5.78c0-3.75,1.08-6.6,3.26-8.55c2.17-1.95,4.99-2.93,8.44-2.93c2.22,0,4.32,0.36,6.3,1.07c1.97,0.72,3.6,1.62,4.89,2.7v6.81
		c0,0.3-0.12,0.56-0.37,0.78c-0.25,0.22-0.59,0.33-1.04,0.33c-0.59,0-1.06-0.2-1.41-0.59c-0.35-0.4-0.62-0.99-0.82-1.78l-0.74-3.41
		c-1.53-0.99-3.33-1.48-5.41-1.48c-1.88,0-3.38,0.58-4.52,1.74c-1.14,1.16-1.7,2.83-1.7,5v6.07h9.63c0.74,0,1.11,0.4,1.11,1.19
		c0,1.48-1.28,2.37-3.85,2.67l-6.89,0.59v27.48l3.33,0.96c0.79,0.25,1.36,0.53,1.7,0.85c0.35,0.32,0.52,0.75,0.52,1.3
		c0,0.69-0.32,1.04-0.96,1.04H2C1.56,53.79,1.21,53.63,0.96,53.31z"
            />
            <path
              class={styles["st0"]}
              d="M36.22,52.53c-2.77-1.48-4.93-3.63-6.48-6.44c-1.56-2.81-2.33-6.17-2.33-10.07c0-3.95,0.73-7.37,2.18-10.26
		c1.46-2.89,3.5-5.11,6.15-6.67c2.64-1.56,5.71-2.33,9.22-2.33c4.84,0,8.71,1.42,11.63,4.26c2.91,2.84,4.37,6.6,4.37,11.29
		c0,1.78-0.4,3.51-1.19,5.18h-25.4c0,4.15,1.12,7.32,3.37,9.52c2.25,2.2,5,3.29,8.26,3.29c1.78,0,3.41-0.23,4.89-0.7
		c1.48-0.47,2.96-1.17,4.44-2.11c0.74-0.49,1.33-0.74,1.78-0.74c0.54,0,1,0.2,1.37,0.59c0.37,0.4,0.56,0.89,0.56,1.48
		c0,0.59-0.21,1.12-0.63,1.59c-0.42,0.47-1.07,0.98-1.96,1.52c-3.01,1.88-6.59,2.81-10.74,2.81C42.14,54.75,38.98,54.01,36.22,52.53
		z M53.7,33.05c0.2-0.54,0.3-1.26,0.3-2.15c0-3.06-0.83-5.44-2.48-7.15c-1.66-1.7-3.86-2.55-6.63-2.55c-2.91,0-5.32,1.05-7.22,3.15
		s-2.97,5-3.22,8.7H53.7z"
            />
            <path
              class={styles["st0"]}
              d="M66.51,53.31c-0.25-0.32-0.37-0.75-0.37-1.3c0-0.79,0.28-1.38,0.85-1.78c0.57-0.4,1.34-0.59,2.33-0.59h1.85
		V5.35h-3.63c-0.94,0-1.41-0.57-1.41-1.7c0-0.84,0.28-1.46,0.85-1.85c0.57-0.39,1.34-0.59,2.33-0.59h8.74v48.44l3.33,0.96
		c0.79,0.25,1.36,0.53,1.7,0.85c0.35,0.32,0.52,0.75,0.52,1.3c0,0.69-0.32,1.04-0.96,1.04H67.54C67.1,53.79,66.75,53.63,66.51,53.31
		z"
            />
            <path
              class={styles["st0"]}
              d="M88.8,53.31c-0.25-0.32-0.37-0.75-0.37-1.3c0-0.79,0.28-1.38,0.85-1.78c0.57-0.4,1.35-0.59,2.33-0.59h1.85v-28
		h-3.63c-0.94,0-1.41-0.57-1.41-1.7c0-0.84,0.28-1.45,0.85-1.85c0.57-0.4,1.35-0.59,2.33-0.59h8.74v32.14l3.33,0.96
		c0.79,0.25,1.36,0.53,1.7,0.85c0.35,0.32,0.52,0.75,0.52,1.3c0,0.69-0.32,1.04-0.96,1.04H89.84C89.39,53.79,89.05,53.63,88.8,53.31
		z M93.35,10.54c-0.91-0.89-1.37-2.05-1.37-3.48c0-1.43,0.46-2.58,1.37-3.44c0.91-0.86,2.06-1.3,3.44-1.3c1.33,0,2.45,0.43,3.37,1.3
		c0.91,0.86,1.37,2.01,1.37,3.44c0,1.43-0.46,2.59-1.37,3.48c-0.91,0.89-2.04,1.33-3.37,1.33C95.42,11.87,94.27,11.42,93.35,10.54z"
            />
            <path
              class={styles["st0"]}
              d="M212.12,53.31c-0.25-0.32-0.37-0.75-0.37-1.3c0-0.79,0.28-1.38,0.85-1.78c0.57-0.4,1.34-0.59,2.33-0.59h1.85
		v-28h-3.63c-0.94,0-1.41-0.57-1.41-1.7c0-0.84,0.28-1.45,0.85-1.85c0.57-0.4,1.34-0.59,2.33-0.59h8.74v32.14L227,50.6
		c0.79,0.25,1.36,0.53,1.7,0.85c0.34,0.32,0.52,0.75,0.52,1.3c0,0.69-0.32,1.04-0.96,1.04h-15.11
		C212.71,53.79,212.36,53.63,212.12,53.31z M216.67,10.54c-0.91-0.89-1.37-2.05-1.37-3.48c0-1.43,0.46-2.58,1.37-3.44
		c0.91-0.86,2.06-1.3,3.44-1.3c1.33,0,2.46,0.43,3.37,1.3c0.91,0.86,1.37,2.01,1.37,3.44c0,1.43-0.46,2.59-1.37,3.48
		c-0.91,0.89-2.04,1.33-3.37,1.33C218.73,11.87,217.58,11.42,216.67,10.54z"
            />
            <path
              class={styles["st0"]}
              d="M236.85,52.08c-2.22-1.78-3.33-4.3-3.33-7.55c0-3.6,1.28-6.26,3.85-7.96c2.57-1.7,5.9-2.88,10-3.52l9.7-1.48
		v-2.96c0-2.27-0.7-4.07-2.11-5.41c-1.41-1.33-3.35-2-5.81-2c-1.43,0-3.05,0.25-4.85,0.74c-1.8,0.49-3.25,1.09-4.33,1.78l-0.74,3.18
		c-0.4,1.63-1.16,2.44-2.3,2.44c-0.44,0-0.78-0.11-1-0.33c-0.22-0.22-0.33-0.51-0.33-0.85V20.9c2.02-1.19,4.36-2.17,7-2.96
		c2.64-0.79,5.2-1.19,7.66-1.19c4.05,0,7.36,1.04,9.92,3.11c2.57,2.07,3.85,5.04,3.85,8.89v21.03l2.59,0.82
		c0.84,0.3,1.43,0.59,1.78,0.89c0.34,0.3,0.52,0.72,0.52,1.26c0,0.69-0.32,1.04-0.96,1.04h-10.74v-4.67h-0.3
		c-1.33,1.73-3.04,3.1-5.11,4.11c-2.07,1.01-4.37,1.52-6.89,1.52C241.76,54.75,239.07,53.86,236.85,52.08z M251.92,48.94
		c1.56-0.82,2.8-1.9,3.74-3.26c0.94-1.36,1.41-2.83,1.41-4.41v-5.48l-8.3,1.33c-2.62,0.44-4.65,1.22-6.11,2.33
		c-1.46,1.11-2.19,2.68-2.19,4.7c0,1.93,0.6,3.41,1.82,4.44c1.21,1.04,2.8,1.56,4.78,1.56C248.75,50.16,250.37,49.75,251.92,48.94z"
            />
            <path
              class={styles["st0"]}
              d="M273.95,53.31c-0.25-0.32-0.37-0.75-0.37-1.3c0-0.79,0.28-1.38,0.85-1.78c0.57-0.4,1.34-0.59,2.33-0.59h1.85
		v-28h-3.63c-0.94,0-1.41-0.57-1.41-1.7c0-0.84,0.28-1.45,0.85-1.85c0.57-0.4,1.34-0.59,2.33-0.59h8.37v4.96h0.3
		c4.15-3.8,8.39-5.7,12.74-5.7c3.51,0,6.41,1.03,8.7,3.07c2.3,2.05,3.44,4.83,3.44,8.33v21.48l3.33,0.96
		c0.79,0.25,1.36,0.53,1.7,0.85c0.35,0.32,0.52,0.75,0.52,1.3c0,0.69-0.32,1.04-0.96,1.04H299.8c-0.44,0-0.79-0.16-1.04-0.48
		c-0.25-0.32-0.37-0.75-0.37-1.3c0-0.79,0.28-1.38,0.85-1.78c0.57-0.4,1.35-0.59,2.33-0.59h1.85V28.75c0-2.22-0.64-4-1.92-5.33
		c-1.28-1.33-3.11-2-5.48-2c-1.83,0-3.65,0.49-5.48,1.48c-1.83,0.99-3.51,2.32-5.04,4v22.74l3.33,0.96c0.79,0.2,1.36,0.47,1.7,0.82
		c0.35,0.35,0.52,0.79,0.52,1.33c0,0.69-0.32,1.04-0.96,1.04h-15.11C274.55,53.79,274.2,53.63,273.95,53.31z"
            />
            <path
              class={styles["st0"]}
              d="M328.54,52.53c-2.66-1.48-4.74-3.65-6.22-6.52c-1.48-2.86-2.22-6.3-2.22-10.29c0-4,0.74-7.42,2.22-10.26
		c1.48-2.84,3.55-5,6.22-6.48c2.67-1.48,5.73-2.22,9.18-2.22c3.46,0,6.52,0.74,9.18,2.22c2.66,1.48,4.74,3.64,6.22,6.48
		c1.48,2.84,2.22,6.26,2.22,10.26c0,4-0.74,7.43-2.22,10.29c-1.48,2.86-3.55,5.04-6.22,6.52c-2.67,1.48-5.73,2.22-9.18,2.22
		C334.27,54.75,331.2,54.01,328.54,52.53z M345.54,46.49c1.85-2.54,2.78-6.14,2.78-10.78c0-4.64-0.93-8.22-2.78-10.74
		c-1.85-2.52-4.46-3.78-7.81-3.78c-3.41,0-6.04,1.26-7.89,3.78c-1.85,2.52-2.78,6.1-2.78,10.74c0,4.64,0.94,8.23,2.81,10.78
		c1.88,2.54,4.49,3.81,7.85,3.81C341.08,50.31,343.68,49.04,345.54,46.49z"
            />
            <path
              class={styles["st0"]}
              d="M369.27,52.53c-2.67-1.48-4.74-3.65-6.22-6.52c-1.48-2.86-2.22-6.3-2.22-10.29c0-4,0.74-7.42,2.22-10.26
		s3.55-5,6.22-6.48c2.67-1.48,5.73-2.22,9.18-2.22c3.46,0,6.52,0.74,9.18,2.22c2.67,1.48,4.74,3.64,6.22,6.48
		c1.48,2.84,2.22,6.26,2.22,10.26c0,4-0.74,7.43-2.22,10.29c-1.48,2.86-3.55,5.04-6.22,6.52c-2.67,1.48-5.73,2.22-9.18,2.22
		C375,54.75,371.94,54.01,369.27,52.53z M386.27,46.49c1.85-2.54,2.78-6.14,2.78-10.78c0-4.64-0.93-8.22-2.78-10.74
		c-1.85-2.52-4.46-3.78-7.81-3.78c-3.41,0-6.04,1.26-7.89,3.78c-1.85,2.52-2.78,6.1-2.78,10.74c0,4.64,0.94,8.23,2.81,10.78
		c1.87,2.54,4.49,3.81,7.85,3.81S384.42,49.04,386.27,46.49z"
            />
            <polygon
              class="st1"
              points="192.05,41.86 197.32,49.57 192.05,41.86 	"
            />
            <path
              class={styles["st0"]}
              d="M112.5,49.64h-1.48c-0.79,0-1.47,0.23-2.04,0.67c-0.57,0.44-0.85,1.01-0.85,1.7c0,0.55,0.12,0.98,0.37,1.3
		c0.25,0.32,0.59,0.48,1.04,0.48h13.18c0.59,0,0.89-0.34,0.89-1.04c0-1.03-0.72-1.75-2.15-2.14l-3.63-1.04l4.86-7.1l-2.78-3.66
		L112.5,49.64z"
            />
            <path
              class={styles["st0"]}
              d="M123.74,24.7l-4.8-7.21h-7.33c-0.79,0-1.47,0.22-2.04,0.67c-0.56,0.44-0.85,1.03-0.85,1.77
		c0,1.14,0.47,1.71,1.41,1.71h3.26l5.93,8.86L123.74,24.7z"
            />
            <path
              class={styles["st0"]}
              d="M206.17,50.31c-0.57-0.44-1.25-0.67-2.04-0.67h-1.48l-7.82-11.44l-2.78,3.66l5.27,7.71l-3.63,1.04
		c-1.43,0.39-2.15,1.11-2.15,2.14c0,0.7,0.3,1.04,0.89,1.04h13.18c0.45,0,0.79-0.16,1.04-0.48c0.25-0.32,0.37-0.75,0.37-1.3
		C207.02,51.32,206.74,50.75,206.17,50.31z"
            />
            <polygon
              class="st0"
              points="192.05,41.86 197.32,49.57 192.05,41.86 	"
            />
            <path
              class={styles["st0"]}
              d="M201.76,21.65h3.26c0.94,0,1.41-0.57,1.41-1.71c0-0.74-0.29-1.33-0.85-1.77c-0.57-0.45-1.25-0.67-2.04-0.67
		h-7.33l-5.2,7.83l4.41,5.8L201.76,21.65z"
            />
            <polygon
              class="st2"
              points="145.43,56.69 128.38,34.27 145.43,11.86 140.66,8.23 120.85,34.27 140.66,60.32 145.43,56.69 	"
            />
            <polygon
              class="st2"
              points="173.61,60.32 193.42,34.27 173.61,8.23 168.83,11.86 185.88,34.27 168.83,56.69 173.61,60.32 	"
            />
            <polyline
              class="st2"
              points="161.97,0 147.54,67.32 153.41,68.55 167.83,1.23 	"
            />
          </g>
        </svg>
        </a>
      </div>
     
      <div
        className={`${styles["navigation"]} ${isActive && styles.activated}`}
      > 
        <ol start="0" className={`${darkMode && styles.dark}`}>
          <li>
            {/*every li had a tag for css i took off but still works*/}
          <NavLink to={'/'} onClick={closeNav} >{"<home/>"}</NavLink>
          </li>
          <li>
            <NavLink to={'/about'} onClick={closeNav} >{"<about/>"}</NavLink>
          </li>
          <li>
          <NavLink to={'skills'} onClick={closeNav}>{'<skills/>'}</NavLink>
          </li>
          <li>
            <NavLink to={'/contact'} onClick={closeNav}>{"<contact/>"}</NavLink>
          </li>
        </ol>
        <div className={styles["media"]}>
        <a href="mailto:perezfelixernesto@gmail.com" ><BsEnvelopeAtFill/></a>
        <a href="https://www.linkedin.com/in/felixxianoo/"
        target={"_blank"} rel="noreferrer"><BsLinkedin/></a>
        <a href="https://github.com/felixxianoo/"
        target={"_blank"} rel="noreferrer"><BsGithub/></a>
        </div>
      </div>
      <div className={styles["hamburger"]}>
        <input
        className={`${styles.hamburger} ${isActive ? styles.activated : ''}`}
          type="checkbox"
          onClick={toggleNav}
        />
        <div>{lines}</div>
      </div>
      <div className={styles["footer"]}>
        &copy;felixxianoo 2024
      </div>
    </div>
  );
}

export default Nav;
